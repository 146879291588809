<template>
  <div class="d-flex flex-column align-items-center">
    <h1 class="mt-5">Service Requirement</h1>

    <p>Please select one of the services below:</p>
    <div class="container">
      <div class="">
        <img src="@/assets/logo.svg" alt="logo" height="32" />
        <div class="d-flex flex-two-cols">
          <div
            v-for="option in impairexOptions"
            :key="option.display_name"
            class="flex-basis-500 m-2"
          >
            <ServiceRequirementButton
              :option="option"
              :class="{
                disabled: isDisabled(option),
              }"
            />
          </div>
        </div>
        <div class="mt-5">
          <img src="@/assets/svg/tpdex-logo.svg" alt="logo" height="32" />
          <div class="d-flex">
            <div
              v-for="option in tpdOptions"
              :key="option.display_name"
              class="flex-basis-500 m-2"
            >
              <ServiceRequirementButton
                :option="option"
                :class="{
                  disabled: isDisabled(option),
                }"
              />
            </div>
          </div>
        </div>
        <div class="mt-5">
          <img src="@/assets/svg/emrex-logo.svg" alt="logo" height="32" />
          <div class="d-flex">
            <div
              v-for="option in emrOptions"
              :key="option.display_name"
              class="flex-basis-500 m-2"
            >
              <ServiceRequirementButton
                :option="option"
                :class="{
                  disabled: isDisabled(option),
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import ServiceRequirementButton from '@/components/ServiceRequirement/ServiceRequirementButton.vue'

// ImpairEx
const impairexOptions = ref([
  {
    display_name: 'Permanent Impairment Assessment',
    short_form: 'Work Injury',
    allowed_access: ['has_wpi_access'],
    route_name: 'body-part-selection',
  },
  {
    display_name: 'Motor Vehicle Accident Injury Assessment',
    short_form: 'MVA',
    allowed_access: null,
    route_name: null,
  },
  {
    display_name: 'Comcare Impairment Assessment',
    short_form: 'Comcare',
    allowed_access: null,
    route_name: null,
  },
])

// Others

const tpdOptions = ref([
  {
    display_name: 'Total Permanent Disability Assessment',
    short_form: 'TPD',
    allowed_access: [],
    route_name: 'tpd-lawyer',
    params: { id: 'new' },
  },
])

const emrOptions = ref([
  {
    display_name: 'Electronic Medical File Retrieval',
    short_form: 'EMR',
    allowed_access: null,
    route_name: null,
  },
])

// Vuex
const store = useStore()

// Gatekeep user from clicking based on profile permission
function isDisabled(option) {
  return option.allowed_access &&
    option.allowed_access?.every((a) => store.state.profile[a] === true) &&
    option.route_name
    ? false
    : true
}
</script>

<style lang="scss" scoped>
.flex-two-cols {
  flex-wrap: wrap;
  width: 100%;
}

.flex-basis-500 {
  flex-basis: 500px;
}
</style>
